import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const testimonials = [
    {
      quote:
        "Suraj is an exceptional video editor, always incredibly responsive and accommodating even with last-minute requests, consistently delivering high-quality work exactly as envisioned. I am so grateful to have him producing our Gen She Podcast.",
      name: "Avni Barman",
      position: "Founder of Gen She",
      image: "/avni.png",
    },
    {
      quote:
        "Suraj is the most hardworking editor I have worked with. Super cooperative and polite. He has never sacrificed the quality in any video. Happy working with him.",
      name: "Vansh Gujral",
      position: "Content Strategist At TRS Clips",
      image: "/vansh.png",
    },
  ];

  return (
    <section id="testimonials" className="py-10 w-full bg-white dark:bg-gray-900 transition-all relative overflow-hidden">
      <div className="container mx-auto text-center px-4 max-w-4xl relative py-28">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-black dark:text-white mb-8">
          <span className="text-yellow-500 dark:text-yellow-400">Clients Feedback</span>
        </h2>

        {/* Slider Container */}
        <div className="max-w-3xl mx-auto relative">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="p-4 flex flex-col items-center">
                {/* Quote */}
                <p className="text-lg text-black dark:text-gray-300 italic mb-4 px-4">
                  "{testimonial.quote}"
                </p>

                {/* Client Info */}
                <div className="flex flex-col items-center space-y-3">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-16 h-16 rounded-full border-2 border-yellow-500 shadow-lg"
                    onError={(e) => (e.target.src = "/fallback.png")}
                  />
                  <h4 className="text-lg font-bold text-black dark:text-white">{testimonial.name}</h4>
                  <p className="text-sm text-gray-700 dark:text-gray-400">{testimonial.position}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

// Custom Previous Arrow (Placed Outside)
const CustomPrevArrow = ({ onClick }) => {
  return (
    <button
      className="absolute left-[-50px] top-1/2 transform -translate-y-1/2 z-10 
                 p-3 bg-gray-200 dark:bg-gray-700 rounded-full shadow-md transition-all 
                 hover:scale-110 hover:bg-gray-300 dark:hover:bg-gray-600"
      style={{ fontSize: "16px" }}
      onClick={onClick}
    >
      <FaChevronLeft className="text-gray-700 dark:text-gray-300" />
    </button>
  );
};

// Custom Next Arrow (Placed Outside)
const CustomNextArrow = ({ onClick }) => {
  return (
    <button
      className="absolute right-[-50px] top-1/2 transform -translate-y-1/2 z-10 
                 p-3 bg-gray-200 dark:bg-gray-700 rounded-full shadow-md transition-all 
                 hover:scale-110 hover:bg-gray-300 dark:hover:bg-gray-600"
      style={{ fontSize: "16px" }}
      onClick={onClick}
    >
      <FaChevronRight className="text-gray-700 dark:text-gray-300" />
    </button>
  );
};

export default Testimonials;
