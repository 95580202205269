import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Statistics from "./components/Stats";
import AboutMe from "./components/AboutMe";
import Contact from "./components/Contact";
import ProjectShowcase from "./components/Projects";
import Testimonial from "./components/Testimonial";
import { useState } from "react";
import Footer from "./components/Footer";

function App() {
  const [activeCategory, setActiveCategory] = useState("videoEditing");
  

  return (
<div>
  <Navbar setActiveCategory={setActiveCategory} />

  <Hero/>
  <Statistics/>
  <ProjectShowcase activeCategory={activeCategory} />
  <Testimonial/>  
  <AboutMe/>
  <Contact/> 
  <Footer/>

 
    </div>
  );
}

export default App;
