import { motion } from "framer-motion";

const AboutMe = () => {
  return (
    <section className="relative bg-white dark:bg-gray-900 py-28 px-6 overflow-hidden" id="AboutMe">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-20 p-6">
        
        {/* Profile Image with Name Below */}
        <div className="flex flex-col items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="w-48 h-48 rounded-full overflow-hidden shadow-lg"  // Reduced size
          >
            <motion.img 
              src="/suraj.jpg" 
              alt="Dhiraj Pramod Badgujar" 
              className="w-full h-full object-cover rounded-full transition-transform duration-300 hover:scale-105"
            />
          </motion.div>

          {/* Larger Name Below Image */}
          <motion.h3
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="mt-4 text-3xl font-bold text-gray-900 dark:text-white"  // Increased font size
          >
            Suraj Badgujar
          </motion.h3>
        </div>

        {/* About Me Content */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-center md:text-left max-w-2xl"
        >
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            About <span className="text-yellow-500 dark:text-yellow-300">Me</span>
          </h2>

          <p className="text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
            I’ve been working as a video editor for the past 3 years. In this time,
            I’ve edited 500+ videos and worked with creators such as 
            <span className="text-yellow-600 dark:text-yellow-400">
              {" "}AI Ankit, Gen She Pod, Sushasan Podcast, TIGP, The Bookholics
            </span>, and many others.<br />
            Also, I’m creating my own content on Instagram, which has helped me understand content strategy and audience engagement, 
            adding value to my editing approach.
            Currently, I’m working on a social media agency called Digidit Creative Services, 
            which focuses on helping creators and brands grow with quality edits, scripts, trends, and strategies!
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutMe;
