import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        
        }
      };
  return (
    <div>
      
      <hr className="border-0 h-[2px] bg-gray-300 dark:bg-gray-700 opacity-60 dark:opacity-50 transition-all duration-300" />
      <div className="absolute inset-0 flex justify-center overflow-hidden">
        <div className="w-3 h-3 bg-yellow-500 dark:bg-yellow-400 rounded-full shadow-md dark:shadow-yellow-500/50 animate-pulse"></div>
      </div>
    <footer className="bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white transition-all duration-300 py-8">
      <div className="container mx-auto px-4 md:px-10 lg:px-20 text-center">
        {/* Social Media Icons */}
        

        {/* Navigation Links */}
        <nav className="mb-6">
          <ul className="flex flex-wrap justify-center space-x-4 md:space-x-6">
            <li><a onClick={() => scrollToSection("AboutMe")} className="hover:text-yellow-500 transition-colors">About</a></li>
            <li><a onClick={() => scrollToSection("Projects")} className="hover:text-yellow-500 transition-colors">Portfolio</a></li>
            <li><a onClick={() => scrollToSection("contact")} className="hover:text-yellow-500 transition-colors">Contact</a></li>
          </ul>
        </nav>

        {/* Copyright */}
        <p className="text-sm text-gray-600 dark:text-gray-400">
          &copy; {new Date().getFullYear()} Digidit Creative Services. All Rights Reserved.
        </p>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
