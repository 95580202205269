import { useState, useEffect } from "react";
import { Menu, X, Moon, Sun } from "lucide-react";

const Navbar = ({ setActiveCategory }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [menuOpen, setMenuOpen] = useState(false);

  const logo = theme === "dark" ? "/logo_w.png" : "/logo_bb.png";

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setMenuOpen(false);
    }
  };

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-white dark:bg-gray-900 shadow-md p-4 overflow-hidden">

      <div className="max-w-7xl mx-auto flex justify-between items-center w-full">
        {/* Logo */}
        <div className="flex items-center space-x-2 cursor-pointer">
          <img
            src={logo}
            alt="Logo"
            className="w-10 h-10 transition-all duration-300"
            onClick={() => scrollToSection("Hero")}
          />
          <span className="text-xl font-bold text-gray-800 dark:text-white dark:hover:text-yellow-400 hover:text-yellow-500">
            <button onClick={() => scrollToSection("Hero")}>Digidit</button>
          </span>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-6">
          <button onClick={() => scrollToSection("Projects")} className="text-gray-800 dark:text-white hover:text-yellow-500 dark:hover:text-yellow-400">
            Portfolio
          </button>
          
          <button onClick={() => scrollToSection("testimonials")} className="text-gray-800 dark:text-white hover:text-yellow-500 dark:hover:text-yellow-400">
            Testimonials
          </button>
          <button onClick={() => scrollToSection("AboutMe")} className="text-gray-800 dark:text-white hover:text-yellow-500 dark:hover:text-yellow-400">
            About Me
          </button>
          <button onClick={() => scrollToSection("contact")} className="text-gray-800 dark:text-white hover:text-yellow-500 dark:hover:text-yellow-400">
            Contact
          </button>
          {/* Theme Toggle */}
          <button onClick={() => setTheme(theme === "dark" ? "light" : "dark")} className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700">
            {theme === "dark" ? <Sun size={20} className="text-white" /> : <Moon size={20} />}
          </button>
        </div>

        {/* Mobile Menu Toggle */}
        <button className="md:hidden text-gray-800 dark:text-white" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <X size={30} /> : <Menu size={30} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden bg-white dark:bg-gray-900 p-4 mt-2 rounded-lg shadow-lg w-full">
          <div className="flex flex-col space-y-2">
            <button onClick={() => scrollToSection("Projects")} className="text-gray-800 dark:text-white hover:text-yellow-500">
              Portfolio
            </button>
            <button onClick={() => scrollToSection("AboutMe")} className="text-gray-800 dark:text-white hover:text-yellow-500">
              About Me
            </button>
            <button onClick={() => scrollToSection("contact")} className="text-gray-800 dark:text-white hover:text-yellow-500">
              Contact
            </button>
            {/* Theme Toggle for Mobile */}
            <button onClick={() => setTheme(theme === "dark" ? "light" : "dark")} className="p-2 rounded-lg bg-gray-200 dark:bg-gray-700">
              {theme === "dark" ? <Sun size={20} /> : <Moon size={20} />}
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
