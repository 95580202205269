import React, { useState, useEffect, useRef } from "react"; // Added useEffect and useRef imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faVideo, faFileImage, faMicrophone, faFire, faFilm } from "@fortawesome/free-solid-svg-icons";

const projects = {
  videoEditing: [] , // Empty array, add projects as needed
  graphicDesign: [
    { id: 3, src: "/Sariha_art.png", title: "Design 3" },
    { id: 2, src: "/GRAFIC1.jpg", title: "Design 2" },
  ],
};

const projectsv = [
  { title: "Project 1", video: "/video.mp4" },
  { title: "Project 2", video: "/video2.mp4" },
  { title: "Project 3", video: "/video3.mp4" },
  { title: "Project 4", video: "/video4.mp4" },
];

const ProjectShowcase = () => {
  const [activeCategory, setActiveCategory] = useState("videoEditing");
  const videoRefs = useRef([]);

  // Function to pause all other videos when one plays
  const handleVideoPlay = (index) => {
    videoRefs.current.forEach((video, idx) => {
      if (idx !== index && video) video.pause();
    });
  };

  // Pause video when it goes out of the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target;
          if (!entry.isIntersecting && !video.paused) {
            video.pause();
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the video is visible
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, []);

  return (
    <section className="py-20 bg-gray-100 dark:bg-gray-900 transition-all duration-300 text-center overflow-hidden" id="Projects">
      <div className="max-w-6xl mx-auto px-4">
        {/* Title */}
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-10"> My Work</h2>

        {/* Category Tabs */}
        {/* <div className="flex justify-center gap-4 mb-10">
          {[{ id: "videoEditing", icon: faVideo, label: "Video Editing" },
            { id: "graphicDesign", icon: faFileImage, label: "Graphic Design" }
          ].map(({ id, icon, label }) => (
            <button
              key={id}
              onClick={() => setActiveCategory(id)}
              className={`px-6 py-2 rounded-lg font-semibold flex items-center gap-2 transition-all shadow-md ${
                activeCategory === id
                  ? "bg-yellow-500 text-white dark:bg-yellow-400 dark:text-black"
                  : "bg-gray-300 text-black dark:bg-gray-700 dark:text-white"
              }`}
            >
              <FontAwesomeIcon icon={icon} />
              {label}
            </button>
          ))}
        </div> */}

        {/* Projects Grid */}
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {projects[activeCategory].length > 0 ? (
            projects[activeCategory].map((item) => (
              <div key={item.id} className="relative bg-gray-200 dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                {activeCategory === "videoEditing" ? (
                  <iframe
                    src={item.src}
                    title={item.title}
                    className="w-full h-64 object-cover"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <div className="w-full aspect-square">
                    <img
                      src={item.src}
                      alt={item.title}
                      className="w-full h-full object-cover hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-lg text-gray-500 dark:text-gray-400 col-span-full">No projects available</p>
          )}
        </div> */}

        {/* Additional Sections - Only for Video Editing */}
        {activeCategory === "videoEditing" && (
          <>
           
            {/* Shorts Section */}
            <div className="container mx-auto text-center">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-gray-300 mb-6">Shorts</h2>

              {/* Social Media Icons */}
              <div className="flex justify-center space-x-6 mb-8">
                <a href="#Portfolio" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTiktok} className="text-3xl text-gray-900 dark:text-white hover:text-purple-600 dark:hover:text-purple-400 transition-colors" />
                </a>
                <a href="#Portfolio" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="text-3xl text-gray-900 dark:text-white hover:text-pink-600 dark:hover:text-pink-400 transition-colors" />
                </a>
                <a href="#Portfolio" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} className="text-3xl text-gray-900 dark:text-white hover:text-red-600 dark:hover:text-red-400 transition-colors" />
                </a>
              </div>

              {/* Projects Grid (sm: 2 cols, md: 3 cols, lg: 4 cols) */}
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {projectsv.map((project, index) => (
                  <div key={index} className="relative w-full aspect-[9/16] rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-105 bg-white dark:bg-gray-800">
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      loop
                      controls
                      muted
                      onPlay={() => handleVideoPlay(index)}
                      className="w-full h-full object-cover"
                    >
                      <source src={project.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
              </div>
            </div>

             {/* Podcast Section */}
             <div className="mt-16">
              <h2 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900 dark:text-white">
                <FontAwesomeIcon icon={faMicrophone} className="text-3xl text-gray-900 dark:text-white hover:text-purple-600 dark:hover:text-purple-400 transition-colors" /> Podcast
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  "https://www.youtube.com/embed/aPcA7BgGhT0?si=2p6LhVNqtGGa1e0g",
                  "https://www.youtube.com/embed/YHU7NVFrpI4",
                  "https://www.youtube.com/embed/IDDA-mjgO1M?si=Kd5dsPAAvy0Z-6v3",
                  "https://www.youtube.com/embed/ki02G633J4Y?si=ONcMVGRCwVcIvtQK" 
                  
                ].map((src, index) => (
                  <iframe
                    key={index}
                    className="w-full aspect-video rounded-xl shadow-xl transition-transform hover:scale-105"
                    src={src}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ))}
              </div>
            </div>

            {/* Viral Videos Section */}
            <div className="mt-16 py-16">
              <h2 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900 dark:text-white">
                <FontAwesomeIcon icon={faFire} className="text-3xl text-gray-900 dark:text-white hover:text-orange-600 dark:hover:text-orange-400 transition-colors"/> Viral
              </h2>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {[
                  { src: "/5.5M.png", link: "https://youtube.com/shorts/L_4a8CcQHo0?si=1DTMHzKVOZtUAbW4" },
                  { src: "/3.6.png", link: "https://www.instagram.com/reel/CvaKtRQARr0/" },
                  { src: "/10M.png", link: "https://youtube.com/shorts/ZcboBHn-lNk?si=f3zthBR6R_TVTbWg" },
                  { src: "/4.6.png", link: "https://www.instagram.com/reel/CukHO7tAfTK/" }
                ].map((video, index) => (
                  <a
                    key={index}
                    href={video.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative w-full aspect-[9/16] rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-105"
                  >
                    <img src={video.src} alt={`Viral Video ${index + 1}`} className="w-full h-full object-cover" />
                  </a>
                ))}
              </div>
            </div>


            {/* Podcast Section */}
            <div className="mt-16">
              <h2 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900 dark:text-white">
                <FontAwesomeIcon icon={faFilm} className="text-3xl text-gray-900 dark:text-white hover:text-gray-600 dark:hover:text-gray-400 transition-colors" /> Other Projects
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  "https://www.youtube.com/embed/6j5IIw9Pulk?si=R0MVyDT6zu0GKJG2",
                  "https://www.youtube.com/embed/1FJat0b9qp8?si=y0kuRo4eG9k_zlVw"                  
                ].map((src, index) => (
                  <iframe
                    key={index}
                    className="w-full aspect-video rounded-xl shadow-xl transition-transform hover:scale-105"
                    src={src}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ))}
              </div>
            </div>

          </>
        )}
      </div>
    </section>
  );
};

export default ProjectShowcase;
